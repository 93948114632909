<template>
  <a-card :bordered="true" class="header-solid h-full" :loading="loading" ref="toBottom" v-if="!loading">
    <a-form-model ref="form" :model="form" >
      <a-form-item>
        <div class="ant-page-header-heading-title">Add collection of images or image sources</div>
      </a-form-item>
      <a-form-model-item v-bind="formItemLayoutWithOutLabel" v-if="form.resources.length<10">
        <a-button type="dashed" style="width: 60%" @click="add" :style="{'float':'right'}">
          <a-icon type="plus" /> Add Image/Image Source
        </a-button>
      </a-form-model-item>
      <draggable v-model="form.resources" :key="componentKey" @start="drag=true" @end="drag=false" @change="dragged">
        <a-form-model-item
            v-for="(resource, index) in form.resources"
            :key="index"

        >
          <!--      <span style="width: 20px;height: 20px">-->
          <!--      <svg fill="#000000" width="20" height="20px" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><title/><path d="M94.2422,43.7578l-12-12a5.9994,5.9994,0,0,0-8.4844,8.4844L75.5156,42H54V20.4844l1.7578,1.7578a5.9994,5.9994,0,0,0,8.4844-8.4844l-12-12a5.9979,5.9979,0,0,0-8.4844,0l-12,12a5.9994,5.9994,0,0,0,8.4844,8.4844L42,20.4844V42H20.4844l1.7578-1.7578a5.9994,5.9994,0,0,0-8.4844-8.4844l-12,12a5.9979,5.9979,0,0,0,0,8.4844l12,12a5.9994,5.9994,0,1,0,8.4844-8.4844L20.4844,54H42V75.5156l-1.7578-1.7578a5.9994,5.9994,0,0,0-8.4844,8.4844l12,12a5.9979,5.9979,0,0,0,8.4844,0l12-12a5.9994,5.9994,0,0,0-8.4844-8.4844L54,75.5156V54H75.5156l-1.7578,1.7578a5.9994,5.9994,0,1,0,8.4844,8.4844l12-12A5.9979,5.9979,0,0,0,94.2422,43.7578Z"/></svg>-->
          <!--      </span>-->

          <a-icon
              v-if="form.resources.length>1"
              class="dynamic-delete-button"
              type="minus-circle-o"
              :disabled="form.resources.length === 1"
              @click="() => remove(index)"
              :style="{'float':'right','z-index':'1','color':'red'}"
          />
          <a-icon
              v-if="form.resources.length>1"
              class="dynamic-delete-button"
              type="drag"
              :style="{'float':'right','z-index':'1','color':'black'}"
              style="cursor: move;margin-right: 20px"
          />
          <Uploader :key="index" v-if="resource" :index="index" :item="resource"></Uploader>

          <a-divider></a-divider>
        </a-form-model-item>
      </draggable>
      <a-form-item :wrapper-col="{ span: 24 }" :style="{ textAlign: 'right' }">
        <a-space size="small">
          <a-button class="update-btn" type="primary" html-type="submit" :loading="confirmLoading" @click="handleSubmit('form')">
            Update
          </a-button>
          <router-link :to="{name:'Events'}">
            <a-button type="danger" :loading="confirmLoading">
              Cancel
            </a-button>
          </router-link>
        </a-space>
      </a-form-item>

    </a-form-model>
  </a-card>

  <a-card :bordered="true" class="header-solid h-full" style="min-height: 400px" v-else>
    <a-row :gutter="[24, 24]">
      <a-col :span="24" >
        <a-skeleton active title />
        <br>
        <br>
        <a-skeleton active title />
        <a-skeleton  active />
        <a-skeleton active />
        <a-skeleton active />
      </a-col>
    </a-row>
  </a-card>
</template>


<script>
import Uploader from './components/Uploader'
import draggable from 'vuedraggable'

export default {
  components: {Uploader, draggable},
  data() {
    return {
      loading: false,
      id: '',
      form: {
        resources: [
          {
            link_type: 'image',
            image: [],
            image_source: '',
            source_name: '',
          }
        ],
      },
      formItemLayout: {
        labelCol: {
          xs: {span: 24},
          sm: {span: 4},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 20},
        },
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: {span: 24, offset: 0},
          sm: {span: 20, offset: 4},
        },
      },
      confirmLoading: false,
      drag: '',
      componentKey: 1000,
      url: process.env.VUE_APP_SERVER_URL
    };
  },
  computed: {
    selectedItem() {
      return this.$store.getters.selectedEvent
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {name: 'dynamic_form_item'});
    this.form.getFieldDecorator('keys', {initialValue: [], preserve: true});
  },

  methods: {
    dragged() {
      setTimeout(() => {
        this.componentKey++;
      }, 10)
    },
    remove(index) {
      this.form.resources.splice(index, 1);
      this.dragged()
    },

    add() {
      this.form.resources.push({
        link_type: 'image',
        image: [],
        image_source: '',
        source_name: '',
      });
      const el = this.$el.getElementsByClassName('update-btn')[0];
      el.scrollIntoView({
        behavior: "smooth"
      });
    },
    postInfo() {
      this.loading = true
      this.$store.dispatch("FETCH_POST", {id: this.$route.params.id})
          .then((res) => {
            let data =res.data
            this.id=data._id
            if (data.imagesDetail.length)
              this.form.resources=[]

            data.imagesDetail.forEach((item)=>{
              let image= item.type=='image'?[{
                uid: '-1',
                name: item.uploadPath,
                status: 'done',
                url: this.url+item.uploadPath,
                thumbnail: this.url+item.uploadPath,
              }]:[]

              this.form.resources.push(
                  {
                    link_type:item.type,
                    image_source:item.sourceUrl,
                    image:image,
                    source_name:item.sourceName
                  })

            })

          })
          .finally(() => {
            this.loading = false
          })
    },
    handleSubmit(formName) {
      // e.preventDefault();
      this.$refs[formName].validate(valid => {
        if (valid) {
          const formData = new FormData();

          let res = [];
          this.form.resources.forEach((item,i)=>{
            if (item.link_type=='image') {
              formData.append('images', item.image[0]);

              let res_item={
                link_type:item.link_type,
                source_name:item.source_name,
                image:item.image[0].name,
                position:i+1
              }

              res.push(res_item)

            }
            else if (item.link_type=='image_source') {
              res.push({
                link_type:item.link_type,
                image_source:item.image_source,
                source_name:item.source_name,
                position:i+1

              })
            }
          })
          formData.append('resources',JSON.stringify(res))
          this.confirmLoading = true

          this.$store.dispatch("UPDATE_POST_IMAGES", {formData: formData, id: this.id})
              .then((res) => {
                this.$message.success(res.data.message);
                this.$emit('changeTab', '3')
              })
              .catch((err) => {
                // this.$message.error(err.response.data.message);
              })
              .finally(() => {
                this.confirmLoading = false
              });
        } else {
          this.$message.error('Some fields are missing');
          return false;
        }
      });

    },
  },
  mounted() {
    this.postInfo()
  }
}
</script>
<style>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
